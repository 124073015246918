<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 150px">
          <a-select
              class="input"
              v-model="queryParams.cateId"
              placeholder="分类"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in cateList" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item" style="width: 150px">
          <a-select
              class="input"
              v-model="queryParams.type"
              placeholder="平替词/代工厂"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict.cheaperWordType" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.word"
              placeholder="关键字/平替词/代工厂"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <!--        <a-button style="margin-left: 8px" @click="reset">重置</a-button>-->
      </div>
      <a-button type="primary" @click="add" style="margin-left:20px;" icon="plus">
        新增
      </a-button>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('postStatus', 'color')" style="cursor: pointer">
          {{ record.status| dictName("postStatus") }}
        </a-tag>
      </template>
      <template slot="cheaperWordType" slot-scope="text, record">
        <a-tag :color="record.type | dictName('cheaperWordType', 'color')" style="cursor: pointer">
          {{ record.type| dictName("cheaperWordType") }}
        </a-tag>
      </template>
      <template slot="isTop" slot-scope="text, record">
        <a-switch :checked="record.isTop==1" @change="changeTopState(record)" :loading="loading"/>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" icon="edit" size="small" @click="modify(record)">
          修改
        </a-button>
        <a-button type="danger" icon="delete" size="small" @click="deleteOne(record)" style="margin-left:10px">
          删除
        </a-button>
      </template>
    </a-table>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import modifyModal from "./modifyCheaperWordModal";

export default {
  name: "CheaperWordList",
  mixins: [ListMixin],
  components: {modifyModal},
  data() {
    return {
      listGetUrl: "cheaper/list",
      listMethod: "get",
      cateList: [],
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order
        },

        {
          title: "关键字",
          align: "center",
          width: 180,
          dataIndex: "word",
        },
        {
          title: "类型",
          align: "center",
          width: 100,
          dataIndex: "type",
          scopedSlots: {customRender: "cheaperWordType"},
        },
        {
          title: "平替词/代工厂",
          align: "center",
          width: 180,
          dataIndex: "replaceWord",
        },
        {
          title: "分类",
          align: "center",
          width: 150,
          dataIndex: "cateName",
        },
        {
          title: "点赞次数",
          align: "center",
          width: 150,
          dataIndex: "prizeTimes",
        },
        {
          title: "状态",
          width: 80,
          dataIndex: "status",
          align: "center",
          scopedSlots: {customRender: "status"},
          sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order
        },
        {
          title: "是否推荐",
          width: 80,
          align: "center",
          scopedSlots: {customRender: "isTop"},
        },
        {
          title: "提交用户",
          width: 150,
          align: "center",
          dataIndex: "creatorInfo.username",
        },
        {
          title: "提交时间",
          width: 150,
          align: "center",
          dataIndex: "createTime",
        },
        {
          title: "操作",
          width: 180,
          align: "center",
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
  },

  mounted() {
    this.$postJson('/category/cateList', {pageSize: 100, type: 4}).then(res => {
      this.cateList = ((res.data && res.data.records) || [])
          .map(v => {
            return {
              value: v.cateId,
              label: v.cateName
            }
          })
    })
    this.search();
  },
  methods: {
    editSuccess() {
      this.search();
    },
    add() {
      this.$refs.modifyModal.showModal(undefined, 'new');
    },
    changeTopState(record) {
      let that = this;
      let data = {
        id: record.id,
        isTop: record.isTop == 1 ? 0 : 1,
      };
      that.showLoading = true;
      that.$post("cheaper/isTop", data).then(() => {
        that.showLoading = false;
        let mes =
            record.postTop != 1
                ? "ID：" + record.id + "推荐成功"
                : "ID：" + record.id + "取消推荐成功";
        that.$message.success(mes + "请前往小程序查看");
        this.search();
      });
    },
    modify(record) {
      this.$refs.modifyModal.showModal(record, 'update');
    },

    deleteOne(record) {
      let that = this
      this.$confirm({
        title: '删除确认',
        content: '您确定要删除【' + record.word + '】吗？！',
        centered: true,
        onOk() {
          that.$post('cheaper/deleteWord', {id: record.id}).then(() => {
            that.$message.success('删除成功')
            that.search()
          })
        },
        onCancel() {
          that.selectedRowKeys = []
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
